import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import VuexRouterSync from 'vuex-router-sync'
import App from './App'
import JQuery from 'jquery';
import ElementUi from 'element-ui';
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import '../public/css/main.scss';
import '../public/css/media.scss';
import '@/plugins'
window.$ = JQuery;
import '@/locales'
Vue.config.productionTip = false
VuexRouterSync.sync(store, router)
Vue.use(ElementUi, {locale})

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  ...App
})

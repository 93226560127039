import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


switch (window.location.hostname) {
  default:

}

Vue.use(VueResource)

Vue.http.options.crossOrigin = true
Vue.http.options.xhr = {withCredentials: true}

Vue.http.options.progress = function (event) {
  if (event.lengthComputable) {
    NProgress.set(event.loaded / event.total)
  }
}

Vue.http.interceptors.push((request, next) => {

  NProgress.start()
  next((response) => {
    NProgress.done()

    return response
  })
})
export const GetIIN = ''

export const Auth = ''

export const GetIINByEmail = ''

export const Logout = ''

export const SendSMS = ''

export const Api = ''

<template>
  <div id="app" class="app" >

      <Header></Header>
      <router-view class="view router-view"></router-view>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header"
import Footer from "./components/Footer"
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  beforeCreate() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('locale');
    }
    // Vue.http.headers.common['X-Locale'] = this.$i18n.locale;
  },
  mounted() {

  }
}
</script>


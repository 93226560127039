<template>
  <div class="header-wrapper-main">
    <div style="position: relative">
      <div class="ts-container header ">
        <a style="display: block" href="/" class="ts-logo"></a>
        <nav v-if="this.$route.path=='/'" class="header-wrapper">
          <a v-bind:class="{'header-item-active':(this.$route.path=='/' && locationHash=='main')}"
             @click="scrollTo('main')" class="header-item">{{ $t('Главная') }}</a>
<!--          <div style="margin: auto 0" class="dropdown">-->
<!--            <a style="margin: 0" class="header-item">Услуги</a>-->
<!--            <div class="dropdown-content">-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">Разработка сайта или портала</a>-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">Ведение и обслуживание вашего сайта</a>-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">Разработка мобильных приложений</a>-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">IT аутсорсинг</a>-->
<!--              <a class="dropdown-item-navBar" href="">DJOON - поиск сотрудника</a>-->
<!--              <a class="dropdown-item-navBar" href="/call-center ">Услуги контакт-центра</a>-->
<!--            </div>-->
<!--          </div>-->
          <a v-bind:class="{'header-item-active':(this.$route.path=='/' && locationHash=='industries')}"
             @click="scrollTo('industries')" class="header-item">{{ $t('Отрасли') }}</a>
          <router-link v-bind:class="{'header-item-active':this.$route.path=='/cases'}" to="/cases" href="/cases"
                       class="header-item">{{ $t('Кейсы') }}
          </router-link>
          <a v-bind:class="{'header-item-active':(this.$route.path=='/' && locationHash=='clients')}"
             @click="scrollTo('clients')" class="header-item">{{ $t('Клиенты') }}</a>
          <a v-bind:class="{'header-item-active':(this.$route.path=='/contact' && locationHash=='')||(this.$route.path=='/' && locationHash=='contact')}"
             @click="scrollTo('contact')" class="header-item">{{ $t('Контакты') }}</a>
        </nav>
        <nav v-else class="header-wrapper">
          <a href="/#main" v-bind:class="{'header-item-active':(this.$route.path=='/' && locationHash=='main')}"
             @click="scrollTo('main')" class="header-item">{{ $t('Главная') }}</a>
<!--          <div style="margin: auto 0" class="dropdown header-item">-->
<!--            <a class="header-item">Услуги</a>-->
<!--            <div class="dropdown-content">-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">Разработка сайта или портала</a>-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">Ведение и обслуживание вашего сайта</a>-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">Разработка мобильных приложений</a>-->
<!--              <a class="dropdown-item-navBar" href="/software-development ">IT аутсорсинг</a>-->
<!--              <a class="dropdown-item-navBar" href="">DJOON - поиск сотрудника</a>-->
<!--              <a class="dropdown-item-navBar" href="/call-center ">Услуги контакт-центра</a>-->
<!--            </div>-->
<!--          </div>-->
          <a href="/#industries"
             v-bind:class="{'header-item-active':(this.$route.path=='/' && locationHash=='industries')}"
             @click="scrollTo('industries')" class="header-item">{{ $t('Отрасли') }}</a>
          <router-link v-bind:class="{'header-item-active':this.$route.path=='/cases'}" to="/cases" href="/cases"
                       class="header-item">{{ $t('Кейсы') }}
          </router-link>
          <a href="/#clients" v-bind:class="{'header-item-active':(this.$route.path=='/' && locationHash=='clients')}"
             @click="scrollTo('clients')" class="header-item">{{ $t('Клиенты') }}</a>
          <a href="/contact"
             v-bind:class="{'header-item-active':(this.$route.path=='/contact' && locationHash=='')||(this.$route.path=='/' && locationHash=='contact')}"
             @click="scrollTo('contact')" class="header-item">{{ $t('Контакты') }}</a>
        </nav>

        <div class="dropdown">
          <a class="language">{{ $i18n.locale }}</a>
          <div class="dropdown-content">
            <a @click="$i18n.locale='ru'">RU</a>
            <a @click="$i18n.locale='kz'">KZ</a>
            <a @click="$i18n.locale='en'">EN</a>
          </div>
        </div>
        <a @click="scrollTo('write')" class="write">{{ $t('НаписатьНам') }}</a>
        <button @click="adaptiveMenu=!adaptiveMenu" class="adaptive-menu"></button>
      </div>
      <div v-if="adaptiveMenu" class="adaptive-menu-wrapper">
        <button @click="adaptiveMenu=false" class="adaptive-menu-close"></button>
        <div class="adaptive-menu-column-wrapper">
          <div class="adaptive-menu-column">
            <a href="/" @click="scrollTo('main')" class="adaptive-menu-item">{{ $t('Главная') }}</a>
<!--            <div class="dropdown adaptive-menu-item">-->
<!--              <a style="margin: 0!important;" @click="showSubMenu=!showSubMenu"-->
<!--                 class="adaptive-menu-item triangle-list">Услуги</a>-->
<!--              <ul v-if="showSubMenu">-->
<!--                <li><a class="adaptive-menu-item adaptive-menu-item-list" href="/software-development ">Разработка сайта-->
<!--                  или портала</a></li>-->
<!--                <li><a class="adaptive-menu-item adaptive-menu-item-list" href="/software-development ">Ведение и-->
<!--                  обслуживание вашего сайта</a></li>-->
<!--                <li><a class="adaptive-menu-item adaptive-menu-item-list" href="/software-development ">Разработка-->
<!--                  мобильных приложений</a></li>-->
<!--                <li><a class="adaptive-menu-item adaptive-menu-item-list" href="/software-development ">IT-->
<!--                  аутсорсинг</a></li>-->
<!--                <li><a class="adaptive-menu-item adaptive-menu-item-list" href="">DJOON - поиск сотрудника</a></li>-->
<!--                <li><a class="adaptive-menu-item adaptive-menu-item-list" href="/call-center ">Услуги контакт-центра</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
            <a @click="scrollTo('industries')" class="adaptive-menu-item">{{ $t('Отрасли') }}</a>
          </div>
          <div class="adaptive-menu-column">
            <a href="/cases" class="adaptive-menu-item">{{ $t('Кейсы') }}</a>
            <a @click="scrollTo('clients')" class="adaptive-menu-item">{{ $t('Клиенты') }}</a>
            <a @click="scrollTo('contact')" class="adaptive-menu-item">{{ $t('Контакты') }}</a>
          </div>
        </div>
        <div class="adaptive-menu-circles"></div>
        <div class="adaptive-menu-dots"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  data() {
    return {
      showSubMenu: false,
      locationHash: location.hash.slice(1),
      adaptiveMenu: false
    }
  },
  watch: {
    '$route'(to, from) {
      this.adaptiveMenu = false
      this.showSubMenu=false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.adaptiveMenu = false
      this.showSubMenu = false
    },

    scrollTo(block) {
      this.locationHash = block
      if (this.$route.path == '/') {
        const yOffset = -200;
        const element = document.getElementById(block);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        this.adaptiveMenu = false
        this.showSubMenu = false
      } else {
        if (block == 'contact') {
          window.location.href = '/' + block
        } else {
          window.location.href = '/#' + block
        }

      }
    },
  },

}
</script>


<template>
  <div class="footer">
    <div class="ts-container ">
      <div class="footer-wrapper-main">
      <a style="display: block " href="/" class="ts-logo"></a>
      <nav class="footer-wrapper">
        <a @click="scrollTo('main')" class="footer-item" >{{$t('Главная')}}</a>
        <a @click="scrollTo('industries')"  class="footer-item" >{{$t('Отрасли')}}</a>
        <a href="/cases"  class="footer-item" >{{$t('Кейсы')}}</a>
        <a @click="scrollTo('clients')" class="footer-item" >{{$t('Клиенты')}}</a>
        <a @click="scrollTo('contact')" class="footer-item" >{{$t('Контакты')}}</a>
      </nav>
        <div class="dropdown dropdown-footer-help" >
          <a class="language">{{$i18n.locale}}</a>
          <div class="dropdown-content">
            <a @click="$i18n.locale='ru'">RU</a>
            <a @click="$i18n.locale='kz'">KZ</a>
            <a @click="$i18n.locale='en'">EN</a>
          </div>
        </div>
      </div>
      <div class="contact-footer">
        <div class="footer-arrow-left"></div>
        <a class="contact-footer-telegram" target="_blank" href="https://telegram.me/">+7 706 708 0424</a>
        <a v-if="this.$route.path=='/call-center'" class="contact-footer-email"  href="mailto:call-centre@ts-technology.kz">call-centre@ts-technology.kz</a>

        <a v-else class="contact-footer-email"  href="mailto:it@ts-technology.kz">it@ts-technology.kz</a>
        <div class="footer-arrow-right"></div>
      </div>
    </div>
    <div class="copy-right">
      © {{currentYear}} Troubleshooting. {{$t('ПраваЗащищены')}}.
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: ''
    }
  },
  mounted() {
    this.currentYear = new Date()
    this.currentYear = this.currentYear.getFullYear()
  },
  methods:{
    scrollTo(block) {
      if(this.$route.path=='/'){
        const yOffset = -200;
        const element = document.getElementById(block);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        this.adaptiveMenu=false   }
      else{
        if(block=='contact'){
          window.location.href='/'+block
        }
        else{
          window.location.href='/#'+block
        }

      }
    },
  }
}
</script>



export default [
  {
    name:'/',
    path: '/',
    components: {
      default: () => import('@/views/main')
    }
  },
  {
    path: '/cases',
    components: {
      default: () => import('@/views/cases')
    }
  },
  {
    path: '/contact',
    components: {
      default: () => import('@/views/contact')
    }
  },
  {
    path: '/software-development',
    components: {
      default: () => import('@/views/software-development')
    }
  },
  {
    path: '/call-center',
    components: {
      default: () => import('@/views/call-center')
    }
  }


]
